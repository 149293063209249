import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Header from "../components/elements/Header"
import Footer from "../components/footer"

import "../styles/main.sass"

const getData = graphql`
  {
    site {
      info: siteMetadata {
        menu {
          className
          action
          title
          icon
        }
        navigation {
          href
          label
        }
      }
    }
  }
`

const LayoutDefault = ({ children }) => {
  const {
    site: {
      info: { menu, navigation },
    },
  } = useStaticQuery(getData)

  return (
    <>
      <Header menu={menu} navigation={navigation} />
      {children}
      <Footer navigation={navigation} />
    </>
  )
}

export default LayoutDefault
