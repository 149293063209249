import React from "react"
import Hero, { HeroContent } from "../components/hero"
import LayoutDefault from "../layouts/default"
import Seo from "../components/seo"
import { Row, Content, Article } from "../components/article"

const ContactsPage = () => {
  const breadcrumbList = [
    { to: "/", name: "Главная" },
    { to: "/contacts", name: "Контакты" },
  ]

  const content = `
    <p>Поможем разобраться с вопросами дизайна и оформления сообществ Вконтакте и профилей других социальных сетей. Мы обязательно найдем эффективное решение для формирования доверия посетителей, столь необходимого для качественного продвижения вашего бизнеса и бренда.</p>
    <p>Работаем с любыми регионами России и за её пределами.</p>
    <ul>
      <li>Россия, г.Брянск, ул.Красноармейская, 103</li>
      <li>+7(920)600-28-91</li>
      <li>support@homework-cool.ru</li>
    </ul>`

  return (
    <LayoutDefault>
      <Seo
        title="Контакты"
        description="Контакты"
        url="/contacts"
      />
      <Hero breadcrumbs={breadcrumbList} backLink="/">
        <HeroContent>
          <h1 className="heading">Контакты</h1>
        </HeroContent>
      </Hero>
      <Row>
        <Content>
          <Article dangerouslySetInnerHTML={{ __html: content }}/>
        </Content>
      </Row>
    </LayoutDefault>
  )
}

export default ContactsPage